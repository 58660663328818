import React from "react"
import { Container, Header } from "semantic-ui-react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HowToCiteUsMessage from "../components/HowToCiteUsMessage"

const AboutPage = () => (
  <Layout>
    <SEO title="About" />
    <Container text style={{ marginTop: "100px", marginBottom: "100px" }}>
      <Header as="h1" dividing>
        <Header.Content>
          About progmiscon.org
          <Header.Subheader>
            A Curated Inventory of Programming Misconceptions
          </Header.Subheader>
        </Header.Content>
      </Header>
      <p>
        The purpose of this web site is to&nbsp; help teachers who teach
        programming, in schools or universities.
      </p>
      <HowToCiteUsMessage/>

      <ul>
        <li><Link to='/contact'>Contact</Link><br/>Provide feedback and join the community</li>
        <li><Link to='/contributors'>Contributors</Link><br/>Who is behind progmiscon.org?</li>
        <li><Link to='/share'>Share</Link><br/>Share and Embed Misconceptions in your own material</li>
        <li><Link to='/relatedWork'>Related Work</Link><br/>Other misconception collections and research</li>
      </ul>

      <p>
        Progmiscon.org is maintained by a
        <span>
          {" "}
          <Link to="/contributors">community</Link>{" "}
        </span>
        of programming educators and researchers.
        Much of the initial collection was gathered in the context of the
        project "Conceptual Change in Learning to Program" funded by a grant to
        the{" "}
        <a href="https://luce.inf.usi.ch/">
          Lugano Computing Education Research Lab (Luce)
        </a>{" "}
        by the Swiss National Science Foundation.
      </p>

    </Container>
  </Layout>
)

export default AboutPage
