import React from 'react'
import { Message } from 'semantic-ui-react'

const HowToCiteUsMessage = () => {
  return (
    <Message info>
    <Message.Header>How to Cite Progmiscon</Message.Header>
    <p>
      If you would like to refer to progmiscon.org and its collection of misconceptions
      in your paper, please cite this ITiCSE '21 paper:
    </p>
    <p>
      Luca Chiodini, Igor Moreno Santos, Andrea Gallidabino, Anya Tafliovich, André L. Santos, Matthias Hauswirth. {' '}
      <strong>A Curated Inventory of Programming Language Misconceptions</strong>. {' '}
      Proceedings of the 2021 ACM Conference on Innovation and Technology in Computer Science Education (ITiCSE '21), June 26-July 1, 2021, Virtual Event, Germany. {' '}
      <a href="https://doi.org/10.1145/3430665.3456343">10.1145/3430665.3456343</a>
    </p>
  </Message>
)
}

export default HowToCiteUsMessage

